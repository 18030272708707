import React from 'react';
import T from 'i18n-react';

import { OtpSetupModalContainer } from 'Components/OtpSetupModal/OtpSetupModalContainer';

import texts from '../texts.json';

import SignupBar from './SignupBar/SignupBar';
import HowItWorks from './HowItWorks';
import MakesCarousel from './MakesCarousel';
import Masthead from './Masthead';
import Testimonials from './Testimonials';

T.setTexts(texts);

const HomePage = () => {
	return (
		<>
			<OtpSetupModalContainer />
			<Masthead />

			<MakesCarousel />

			<HowItWorks />

			<Testimonials />

			<SignupBar />
		</>
	);
};

export default HomePage;
